import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './footer';
import Header from './header';
import { AppContext } from "./context/context"
import {authUser} from "../services/auth"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdWindow from './misc/adWindow';
import { checkAds, clearAds } from '../data/checkAds';
import AlertBottom from './misc/alertBottom';
// import Home from './home/home';
// import AppPlayer from './player/appPlayer';

function AppVod(props) {
  const [info, setInfo] = useState({ name: "guest", login: false, paid: false })
  const [darkMode,setDarkMode] = useState("dark");
  const [ads,setShowAds] = useState(false);

  const changeDarkMode = () => {
    setDarkMode(darkMode == "dark" ? "light" : "dark");
    const classBody = darkMode == "light" ? "dark_body" : ""
    document.body.className = classBody;
    localStorage.setItem("dark",darkMode == "dark" ? "light" : "dark")

    // if(darkMode){
    //   document.body.className = "";
    // }
    // else{
    //   document.body.className = "dark_body";
    // }
  }

  const updateInfo = (_obj) => {
    setInfo({ ...info, ..._obj })
  }

  useEffect(() => {
    // check if paid
    checkIfPaid();
    checkIfDark();
    
  }, []);

  const showAdsOn = (boolean,ads) => {
    console.log("ads",boolean);
    setShowAds(boolean)
  }

  const checkIfDark = () => {
    console.log(localStorage.getItem("dark"));
    document.body.className = localStorage.getItem("dark") == "light" ? "" : "dark_body";
    setDarkMode(localStorage.getItem("dark") || "dark")
  }

  const checkIfPaid = async() => {
    if(localStorage["tok_monkeys"]){
      authUser()
      .then(data => {
        // console.log(data);
        if(data.role == "paid" || data.role == "admin"){
          clearAds()
          updateInfo({paid:true,login: true});
        }
        else{
          checkAds(showAdsOn);
        }
      })
    }
    else{
      checkAds(showAdsOn);
    }
  }

  return (
    <AppContext.Provider value={{ info, updateInfo, darkMode, changeDarkMode, ads,showAdsOn  }}>
      <React.Fragment>
        <AlertBottom />
        <AdWindow />
        <Header />
        <Outlet />
        {/* <AppPlayer /> */}
        {/* <Home /> */}
        <Footer />
        <ToastContainer
          position="top-left" autoClose={5000} hideProgressBar={false}
          newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover
        />
      </React.Fragment>
    </AppContext.Provider>
  )
}

export default AppVod