import React, { useEffect, useRef, useState, useContext } from 'react';
import { API_URL, doApiGet, sortById } from '../../services/apiService';
import { codeVideo } from '../../services/viemoUtube';
import InfoPlayer from './infoPlayer';
import "./player.css"
import VideoBtnItem from './videoBtnItem';
import { AppContext } from "../context/context";
import ScrollTop from '../misc/scrollTop';
import { useParams } from 'react-router-dom';
// import ChooseSeason from './chooseSeason';
import Loading from '../misc/loading';

// for vimeo https://www.npmjs.com/package/@u-wave/react-vimeo
let watched_ar = []; // איי די של סרטונים שהמשתמש צפה ונשמרים בלוקאל
let timer;
function AppPlayer(props) {
  const { info } = useContext(AppContext);
  const [h, setH] = useState(200);
  const [playerClass,setPlayerClass] = useState("col-md-9 player_div p-0 order-2")
  const [codeVid, setVidCode] = useState("657295309")
  const [vidKind, setVidKind] = useState("vimeo")
  const [vid_ar, setVidAr] = useState([])
  const [course, setCourse] = useState({})
  const [currentVideo, setCurrentVideo] = useState({})
  const [firstVid, setFirstVid] = useState({})
  const [buyCourse,setBuyCourse] = useState(false);

  let playerRef = useRef();
  let id_course
  let id_video
  let counterSub = 0; // סופר נושאים
  let counterVid = 0; // סופר סרטי וידיאו
  const params = useParams();

  const changeVideo = (_code, _site, _btnId, _curVid) => {
    setCurrentVideo(_curVid);
    setVidCode(_code);
    setVidKind(_site); // youtube of viemo for now
    // console.log(vid_ar);
    let temp_ar = vid_ar.map((item) => {
      item.play = false;
      // console.log(_btnId,item._id)
      if (item._id === _btnId) {
        item.play = true;
        item.watched = true;
        clearTimeout(timer)
        saveWatched(item._id);
      }
      return item;
    })
    // console.log("temp",temp_ar)
    setVidAr(temp_ar)
    setH(playerRef.current.clientHeight - 8)
  }

  const saveWatched = (_id) => {
    timer = setTimeout(() => {
      watched_ar.push(_id)
      watched_ar = [...new Set(watched_ar)]
      console.log(watched_ar);
      localStorage.setItem("video_watched", JSON.stringify(watched_ar))
    }, 1000 * 50);
  }

  useEffect(() => {
    // console.log(playerRef.current.clientHeight)
    setH(playerRef.current.clientHeight)
    doApi();
    // מפענח קורסים שנרכשו
    // checkPayCoursenpm s()
  
    // check if logged in and if paid
  }, [])

  const checkPayCourse = () => { 
    const data = JSON.parse(localStorage.getItem("body_monkeys"));
    // console.log(data);
    try{
      if(data){

        const courses_ar = data.forfor.map(val => {
        return String((val - 20) / data.paypalId)
        })
        if(courses_ar.includes(params.courseId)){
          // console.log('🍏🍏🍏 own it' );
          // setBuyCourse(true)
          return true
        }
        console.log(courses_ar,params.courseId);
      }
    }
    catch(err){
      console.log(err);
      return false;
    }
    return false
  }

  const doApi = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    // id_course = urlParams.get("course") || "882";
    id_course = params.courseId || urlParams.get("course") || "882";
    id_video = params.videoId || null;
    console.log(id_course)
    let url = API_URL + "/videos/byCourse/" + id_course;
    console.log(url)
    let data = await doApiGet(url);
    // console.log(data);
    let url2 = API_URL + "/courses/single/" + id_course
    let courseData = await doApiGet(url2);
    // console.log(courseData);
    data = sortById(courseData.videos_sort, data);
    //  בודק אם צפה כבר בסרטון
    if (localStorage["video_watched"])
      watched_ar = JSON.parse(localStorage["video_watched"]);
    data.forEach(item => { watched_ar.includes(item._id) ? item.watched = true : item.watched = false })
    setVidAr(data);
    setCourse(courseData)
    console.log("course", course);

    // מפעיל סרטון בהתחלה
    if(!id_video){
      setFirstVid(data[1]);
    }
    else{
      let currentVid = data.find(item => item.short_id == id_video)
      console.log(currentVid)
      setFirstVid(currentVid);
    }

  }

  // בודק אם הכתובת למעלה התשנתה של הוידיאו
  useEffect(() => {
    if(params.videoId && vid_ar.length > 0){
      let currentVid = vid_ar.find(item => item.short_id == params.videoId)
      setFirstVid(currentVid);
    }
  },[params.videoId])

  useEffect(() => {
    if (firstVid.url_video) {
      changeVideo(codeVideo(firstVid.url_video, firstVid.video_company), firstVid.video_company, firstVid._id, firstVid);
    }
  }, [firstVid])

  // מרחיב את הפלייר
  const changePlayerWidth = () => {
    if(playerClass == "col-md-9 player_div p-0 order-2"){
      setPlayerClass("col-md-9 player_div player_div_expand p-0 order-2")
      setTimeout(() => {
        setH(playerRef.current.clientHeight - 8)

      },200)
    }
    else{
      setH(playerRef.current.clientHeight - 200)
      setTimeout(() => {
        setPlayerClass("col-md-9 player_div p-0 order-2")
        setH(playerRef.current.clientHeight - 8)

      },200)
    }
  }

  return (
    <div className='container-fluid'>
      <ScrollTop />
      {/* בעתיד יכול להגיע מלמעלה ולעבור בין עונות במהירות */}
        {/* <ChooseSeason/> */}
      <div className="row">
        <div className="col-md-3 movies_list p-0 order-md-1 order-3 side_list_player">
        {/* <Loading /> */}
          {vid_ar.length == 0 ? <Loading /> : ""}
          {vid_ar.map(item => {
            item.paid = info.paid;
            if(item.paid == false){
              item.paid = checkPayCourse();
            }
            if (item.subject) { item.counter = counterSub++ }
            else { item.counter = counterVid++; }
            return (
              <VideoBtnItem key={item._id} item={item} changeVideo={changeVideo} />
            )
          })}
         
        </div>
        <div className={playerClass}>

          <div ref={playerRef} className='player_inside bg-dark'>
            <div className='expan_min_icon d-none d-md-block'>
              {!playerClass.includes("player_div_expand") ? 
              <img onClick={changePlayerWidth} src="/images/expan_icon.png" /> :
              <img onClick={changePlayerWidth} src="/images/mini_icon.png" />
            }
            </div>
            {vidKind === "vimeo" ?
              <iframe key={codeVid} src={`https://player.vimeo.com/video/${codeVid}?h=458ee7ece4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`} width="100%" height={h} frameBorder="0" allow="autoplay; fullscreen;" allowFullScreen title="word_elem3_start"></iframe>
              :
              <iframe key={codeVid} width="100%" height={h} src={`https://www.youtube.com/embed/${codeVid}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowFullScreen></iframe>
            }
          </div>
          <InfoPlayer course={course} video={currentVideo} />
        </div>
      </div>
    </div>
  )
}

export default AppPlayer