import React from 'react'
import ScrollTop from './scrollTop'

export default function ForPay() {
  return (
    <div className='container p-4 text-center' style={{ minHeight: "85vh" }}>
      <ScrollTop />
      <p className='display-5'>
        על מנת לרכוש מנוי פרימיום פנה אלינו טלפונית/ווטסאפ
      </p>
      <p className='h4 text-success'>
        למגיעים מעמוד זה קבלו 5 אחוז הנחה
        בונוס על החבילה המקצועית או העסקית
      </p>
      <p className='h4 text-info'>
        2 אחוז הנחה ינתנו לחבילה הזמנית
      </p>
      <img src="images/phone_me.png" alt="phone 050-8893350" width="380" />
      <p className='h4'>זמינים לשירותכם מהשעה 08:00 עד 20:00  בערב - ימי א' עד ה'    </p>
      <p className='h4'> ימי ו' עד כניסת השבת</p>
      <p className='h2 mt-4'>ניתן לשלם ב BIT / PAYBOX או העברה בנקאית</p>
      <p className='h3 mt-4 text-center text-secondary'>
        בשל עניינים טכניים כרגע עמוד הרכישה נעול
        עמכם הסליחה.
      </p>
      <p className='text-center'>ראו פרטים מעלה כיצד לרכוש מנוי</p>
      <a href="https://monkeys.co.il/terms/" target="_blank">
        <p className='text-center'>לפני רכישה יש לקרוא את תקנון מאנקיס, לתקנון לחץ כאן</p>
      </a>
    </div>
  )
}
