import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authUser } from '../services/auth';

function AuthComp(props) {
  let nav = useNavigate();

  useEffect(() => {
    if (!localStorage["tok_monkeys"]) {
      return doExit();
    }
    authUser()
      .then(data => {
        if (!data.status) {
          doExit();
        }
        //console.log(data);
      })
      .catch(err => {
        console.log(err);
        doExit()
      })
  }, [])

  const doExit = () => {
    const currentUrl = window.location.pathname;
    console.log(currentUrl);
    toast.error("עליך להיות משתמש מחובר או להתחבר מחדש כדי להיות באזור זה", { theme: "colored" })
    localStorage.removeItem("tok_monkeys");
    localStorage.removeItem("body_monkeys");
    localStorage.removeItem("user_monkeys");
    nav("/login?backto="+currentUrl)
  }

  return (
    <React.Fragment></React.Fragment>
  )
}

export default AuthComp;