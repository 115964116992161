import React, { useRef } from 'react';
import { API_URL, doApiMethod } from '../../services/apiService';

function StudentInfo(props){

  let inpRef = useRef();

  const onSendClick = () => {
    let url = API_URL + "/users/paid_student/?paid=true&id=" + inpRef.current.value;
    console.log(url)
    doApiMethod(url,"POST",{})
    .then(data => {
      if(data.modifiedCount){
        alert("הצלחה, כדי שהמשתמש יכנס לתוקף לחץ על התנתק והתחבר שוב מחדש, תתחדש!")
      }
      else{
        console.log(data);
        alert("יש טעות נסה שוב")
      } 
    })
  }

  return(
    <div className='container p-4' style={{minHeight:"85vh"}}>
      <h2>קוד קופון</h2>
      <input ref={inpRef} type="text" />
      <button onClick={onSendClick}>שלח</button>
    </div> 
  )
}

export default StudentInfo