import { API_URL, doApiGet } from "./apiService.js";

// פונקציה שבודקת אם למשתמש יש טוקן
// נפעיל את הפונקציה בהתחלה של כל עמוד שבו אנחנו
// יודעים שהוא חייב להיות מחובר
export const authUser = async() => {
  let url = API_URL+"/users/authUser";
  // משתמש בפונקציה של הסרבס ולא בפצטץ' כדי לחסוך
  // לנו כתיבת שורות קוד עם שיגור פטצ עם טוקן
  let data = await doApiGet(url) 
  if(data.status != "ok"){
    return {err:"error"}
  }
  else{
    return data;
  }
}

// פונקציה שבודקת אם המשתמש שילם
export const checkUserPaid = async() => {
  if(localStorage["monkeys_paid"]){
   let data = await authUser();
   console.log("paid",data);
  }
}