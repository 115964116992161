export const prices_ar = [
  {
    name:"מנוי זמני",
    months:2,
    price:250,
    info:"מנוי דו חודשי למאנקיס PASS (לא מתחדש)"
  },
  {
    name:"מנוי מתקדם",
    months:4,
    price:355,
    info:"מנוי 4 חודשים למאנקיס PASS  (לא מתחדש)"
  },
  {
    name:"מנוי קבוע",
    months:24,
    price:890,
    info:"מנוי 24 חודשים למאנקיס PASS (לא מתחדש)"
  }
  ,
  {
    name:"מנוי טסט טסט",
    months:1,
    price:250,
    info:"טסט"
  }
]

prices_ar[8810] = {
  name:"מנוי קופון מיוחד - לחברי מאנקיס",
  months:3,
  price:350,
  info:"מנוי 3 חודשים למאנקיס PASS  (לא מתחדש)"
}

prices_ar[8410] = {
  name:"מנוי קופון מיוחד - לחברי מאנקיס",
  months:6,
  price:450,
  info:"מנוי 6 חודשים למאנקיס PASS  (לא מתחדש)"
}

prices_ar[8411] = {
  name:"מנוי קופון מיוחד - לחברי מאנקיס",
  months:12,
  price:700,
  info:"מנוי שנתי למאנקיס PASS  (לא מתחדש)"
}

prices_ar[9995] = {
  name:"מנוי קופון מיוחד מבצע - לחברי מאנקיס",
  months:6,
  price:390,
  info:"מנוי 6 חודשים למאנקיס PASS  (לא מתחדש)"
}