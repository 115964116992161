import React from 'react'
import Carosela from './carosela'

export default function Recommend() {
  return (
    <div className='container text-center' style={{ maxWidth: "1100px", minHeight: "200px" }}>
      <h2>המלצות עלינו</h2>
      <p>קרא מה אנשים ברשת ובתעשיה כותבים עלינו</p>
      <div className='bg-dark ' >

        <Carosela />
        
      </div>
      <section className='mt-3 h6'>
          <p>בנוסף מזמינים אותך להתחיל אצלנו מהעונות הראשונות (1 עד 7) ולהבחך בעצמך
            בשיטת הלימוד הייחודית לנו
          </p>
        </section>
    </div>
  )
}
