import React, { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';

function CourseItem(props) {
  const [showInfo, setShowInfo] = useState(false);
  const nav = useNavigate(); 

  const item = props.item;
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 px-2 py-3 py-md-2">
      <div className="box_course text-white h-100">
        <div onClick={() => {
          nav("/player/" + item.short_id)
        }} alt={item.name} className="box_pic box_course_list" style={{ backgroundImage: `url(${item.img_url})`, cursor:"pointer" }}>
          <img src={item.img_url} alt={item.name} className="w-100 d-block d-md-none" />
        </div>
        <div class="box_info">
          <h4 className='py-3 px-3 h5 '>{item.title}</h4>

          {/* <Link to={"/player/?course="+item.short_id} >לקורס</Link> */}
          {/* <div className='mb-md-3'> */}
          <div className='box_btns_wrap'>
            {(item.link_outside.length > 4) ?
              <a href={item.link_outside} title={item.info} rel="noreferrer" target="_blank">לקורס</a>
              : <Link to={"/player/" + item.short_id} title={item.info} >
                לקורס
                <i className="fa fa-play me-2 " aria-hidden="true"></i>
              </Link>
            }
            <span style={{ cursor: "pointer" }} href="" onClick={() => { setShowInfo(!showInfo) }} className='me-3 a left_span'>
              מידע נוסף
              <i className="fa fa-info-circle me-2" aria-hidden="true"></i>
            </span>
            
          </div>
          {showInfo ?
              <div className='moreInfo mt-3 px-3 text-end right_span pb-5' dangerouslySetInnerHTML={{ __html: item.info }}>
                
              </div> : ""}
        </div>
      </div>
    </div>
  )
}

export default CourseItem