import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {toast} from "react-toastify"
import { AppContext } from "../context/context"

import CoursesList from './coursesList';
import "./home.css"
import Strip from './strip';

function Logout(props){

  let {updateInfo} = useContext(AppContext);

  let loc = useLocation();
  useEffect(() => {
    // alert("יצאת מהמערכת כמשתמש רשום")
    toast.warning("יצאת מהמערכת כמשתמש רשום, נתראה שוב בקרוב" , {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme:"colored"
      });
    localStorage.removeItem("user_monkeys")
    localStorage.removeItem("tok_monkeys")
    localStorage.removeItem("body_monkeys")
    localStorage.removeItem("monkeys_paid")

    let body = {name:"guest",login:false,paid:false}
    updateInfo(body)
  },[loc])

  return(
    <main className='home'>
        <Strip />
        <CoursesList />
    </main> 
  )
}

export default Logout