import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../context/context';
import ScrollTop from '../misc/scrollTop';
import {prices_ar} from "../../data/prices_json"

function Premium(props) {

  let classPrice = "col-md-4 p-3 text-center"
  let { info } = useContext(AppContext);
  console.log(info)
  return (
    <div className='container-fluid welcome_out pricing'>
      <ScrollTop />
      <div style={{ minHeight: "85vh" }} className='d-flex align-items-center container welcome py-4' >
        <article className='w-100'>
          <h2 className='my-4 text-center h1'>חבילות ל MONKEYS PASS - PREMIUM</h2>
          <div className="row price_list mb-5">
            <article className={classPrice + " order-md-0 order-2"}>
              <div className="box p-2 border my-md-2 ">
                <h2 className='display-5'> דו חודשי </h2>
                <h3 className='display-4 text-info'> {prices_ar[0].price} ₪</h3>
                <h3>מנוי דו חודשי</h3>
                <ul className='text-end'>
                  <li>כל הסרטונים פתוחים לצפייה</li>
                  <li>מעל 500 סרטונים במנוי</li>
                  <li>גישה למצגות מהסרטונים</li>
                  <li>קבצי קוד מסרטונים נתמכים</li>
                  <li>גישה לסרטונים עתידיים שנפרסם במהלך המינוי</li>
                  <li>מעולה ללפני מבחן/פרוייקט</li>
                </ul>
                {info.login ? 
                <Link to="/pay/0#" className='btn btn-success my-4 text-white'>רכוש כאן</Link>
                : <Link to="/login?backto=/premium" className='btn btn-primary my-4'>לרכישה יש להתחבר/להרשם קודם</Link>
                }
              </div>
            </article>

            <article className={classPrice}>
              <div className="box p-2 border pay_glow">
                <h2 className='display-4'>מקצועי</h2>
                <h3 className='display-4 text-info'> {prices_ar[1].price} ₪</h3>
                <h3>מנוי 4 חודשים - פופלרי</h3>
                <ul className='text-end'>
                  <li>כל הסרטונים פתוחים לצפייה</li>
                  <li>הנחה של 30 אחוז במחיר</li>   
                  <li>מעל 500 סרטונים במנוי</li>
                  <li>גישה למצגות מהסרטונים</li>
                  <li>קבצי קוד מסרטונים נתמכים</li>
                  <li>גישה לסרטונים עתידיים שנפרסם במהלך המינוי</li>
                </ul>

                {info.login ? 
                <Link to="/pay/1" className='btn btn-success my-4'>רכוש כאן</Link>
                : <Link to="/login?backto=/premium" className='btn btn-primary my-4'>לרכישה יש להתחבר/להרשם קודם</Link>
                }
              </div>
            </article>

            <article className={classPrice + " order-md-0 order-2"}>
              <div className="box p-2 border my-md-2">
                <h2 className='display-5'>קבוע פלוס</h2>
                <h3 className='display-4 text-info'>{prices_ar[2].price} ₪</h3>
                <h3>מנוי קבוע - 2 שנים</h3>
                <ul className='text-end'>
                  <li>כל הסרטונים פתוחים לצפייה</li>
                  <li>חשבון לטווח ארוך מוזל </li>
                  <li>מעל 500 סרטונים במנוי</li>
                  <li>גישה למצגות מהסרטונים</li>
                  <li>גישה לסרטונים עתידיים שנפרסם במהלך המינוי</li>
                  <li>מנוי עם סרטונים חדשים</li>
                </ul>

                {info.login ? 
                <Link to="/pay/2#" className='btn btn-success my-4'>רכוש כאן</Link>
                : <Link to="/login?backto=/premium" className='btn btn-primary my-4'>לרכישה יש להתחבר/להרשם קודם</Link>
                }
              </div>
            </article>
          </div>
          <div>
            <a href="https://monkeys.co.il/terms/" className='text-light h5' target="_blank">
              <p className='text-center'>לפני רכישה יש לקרוא את תקנון מאנקיס, לתקנון לחץ כאן</p>
            </a>
            <a href="https://monkeys.co.il/#id_the_form" className='text-light h5' target="_blank">
              <p className='text-center'>לחבילות נוספות , צרו קשר</p>
            </a>
          </div>
        </article>
      </div>
    </div>
  )
}

export default Premium