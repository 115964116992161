import React from 'react';
import { Link } from 'react-router-dom';
// import { API_URL } from '../services/apiService';

function Footer(props) {
  return (
    <footer className='container-fluid'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8 nav_footer text-center text-md-end">
            <p className='p-2 my-0'>
            Monkeys Pass (Premium) - המנוי שיכניס אותך לשוק העבודה בהיי טק בתחום ה FULLSTACK
            </p>
            <div>
            <a href="https://monkeys.co.il/terms/" rel="noreferrer" target="_blank" >תקנון האתר</a>
            <a href="https://monkeys.co.il/" >סרטונים בחינם FULLSTACK</a>
            <Link to={"/player/880"}>קורס REACT</Link>
            <Link to={"/player/900"}>קורס NODEJS</Link>
            <a href="https://monkeys.co.il/study/react_hooks/netlify.zip">לינק לשרת NETLIFY</a>
            <a href="https://monkeys.co.il/#id_the_form">צור קשר</a>
            </div>
          </div>
          <div className="col-md-4 social_icons d-flex justify-content-around">
            <a href="https://www.facebook.com/groups/mean1stack1ofer" rel="noreferrer"  target="_blank" className='icon'>
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <Link to="/forPay" target="_blank" className='icon'>
              <i className="fa fa-whatsapp" aria-hidden="true"></i>
            </Link>
            <a href="https://www.youtube.com/channel/UCrE9ET5Uuteg8mw5oVCxP6w" rel="noreferrer" target="_blank" className='icon'>
              <i className="fa fa-youtube-play" aria-hidden="true"></i>
            </a>
            <a href="https://monkeys.co.il/" rel="noreferrer" target="_blank" className='icon'>
            <i className="fa fa-phone-square" aria-hidden="true"></i>
            </a>
          </div>

        </div>

      </div>
    </footer>
  )
}

export default Footer