import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthComp from '../authComp';
import "./welcome.css"
import { AppContext } from '../context/context';
import NewVideoslist from './home_items/newVideoslist';

function WelcomeBack(props) {

  let { info } = useContext(AppContext);
  return (
    <div className='container-fluid welcome_out'>
      <AuthComp />
      <div style={{  minHeight: "85vh" }} className='d-flex align-items-center container welcome' >
        <article className='w-100'>
          <h1 className='my-4 text-center'>ברוכים השבים לMonkeys Premium </h1>
          {info.paid ?
            <div className='text-center'>
              <h2>הינך משתמש פרימיום </h2>
              <small>* לפרטים נוספים לחץ על החשבון שלי</small>
            </div> :
            <div className='text-center'>
              <h2>הינך משתמש בסיס </h2>
              <small>* להפיכה למשתמש פרימיום  -
               
                <Link to="/premium">
                  לחץ כאן
                </Link>

              </small>
            </div>}
          <div className="row pb-5 info mt-4 ">
            <div className="col-md-6 mb-3 welcom_box" style={{backgroundImage:"url(/images/welcome.jpg)"}} >
              <article className='p-2 pb-3 rounded' style={{ background: "rgba(255,255,255,0.9)" }}>

                <ul>
                  <li>
                    ספרייה מעל 500 סרטונים למנויי פרימיום
                  </li>
                  <li>מאפשר חזרה לאותה נקודה בקורס בה הפסקת</li>
                  <li>עשרות קורסים ומאות סרטוני הדרכה שתוכל לצפות מכל מקום ומכל מכשיר תומך</li>
                  <li>קורסים שמסודרים בדרגת קושי ומידע על ידע מקדים לכל קורס שתמיד תוכל להשלים בקורסים אצלנו</li>
                </ul>
                {!info.paid ?
                  <Link to="/premium">
                    <button className='btn btn-warning shadow'>לחץ כאן להרשמה לפרימיום</button>
                  </Link>
                  : ""
                }
                <Link to="/courses_vod">
                  <button className='btn btn-dark me-2 shadow'>לחץ כאן לצפייה בקורסים</button>
                </Link>
              </article>

            </div>

            <div className="col-md-6">
              <div className='rounded shadow pt-3' style={{ background: "rgba(255,255,255,0.85)" }}>
                <NewVideoslist cssWidth="col-md-6" />
              </div>


            </div>
          </div>
        </article>
      </div>
    </div>
  )
}

export default WelcomeBack