import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from "./context/context"
import { useNavigate, useLocation } from 'react-router-dom';
// import { checkUserPaid } from '../services/auth';
import { API_URL } from '../services/apiService';
import {toast} from "react-toastify";
function Header(props) {
  let { updateInfo, info } = useContext(AppContext);
  let [userObj, setUserObj] = useState("G.W")
  let [showNavMob,setShowNavMob] = useState(false);
  // לינק לחוברות של מאנקיס מנוהל על ידי קוקו10
  let hovrotUrl = "https://docs.google.com/document/d/1uxoZC8dHWBgM4Kvh0OMjII7QxucjfCkzn5Kfx3s8QpY/edit?usp=sharing";


  let loc = useLocation();
  let nav = useNavigate();

  useEffect(() => {
    // console.log(loc)
    if (localStorage["tok_monkeys"] && loc.pathname === "/") {
      nav("/welcome-back")
    }

    if (localStorage["body_monkeys"]) {
      updateInfo(JSON.parse(localStorage["body_monkeys"]))
      let st = localStorage["user_monkeys"].split(" ");
      let obj = {first:st[0] ? st[0][0].toUpperCase() : "G",last:st[1] ? st[1][0].toUpperCase() : "W"}
      setUserObj(obj.first+"."+obj.last);
     
    } 
  }, [])


  const onExitClick = () => {
    if(window.confirm("האם אתה בטוח שתרצה להתנתק מהמשתמש?")){
      console.log("work")
      window.location.href = "/logout"
    }
  }

  const whatNavShow = () => {
    if (!info.login || !localStorage["tok_monkeys"]) {
      return (
        <React.Fragment>
          <Link to="/login">כניסה/הרשמה</Link>
          {/* <Link to="#">הרשמה</Link> */}
          <Link to="/courses_vod">רשימת קורסים</Link>
          <Link to={"#"} className="text-secondary" onClick={
            () => {toast.error("קישור לחוברות/מצגות לימוד , פתוח למשתמשי פרימיום מחוברים בלבד",{theme:"colored"})}
          } >חוברות און ליין</Link>
          <Link to="/premium">מחירים/חבילות</Link>
        </React.Fragment>
      )
    }
    else if (info.paid) {
      // אם שילם
      return (
        <React.Fragment>
          <span className="user_circle shadow" style={{backgroundColor:"Green"}}>{userObj}</span>
          <Link to="/courses_vod">רשימת קורסים</Link>
          <Link to="/user-page">החשבון שלי</Link>
          <Link to="/welcome-back">חדשות</Link>
          <a href={hovrotUrl} target="_blank" >חוברות און ליין</a>
          <Link  onClick={onExitClick} to="#">התנתק</Link>
        </React.Fragment>)
    }
    // אם מנוי שלא שילם
    else {
      return (
        <React.Fragment>
          <span className="user_circle shadow-sm">{userObj}</span>
          <Link to="/courses_vod">רשימת קורסים</Link>
          <Link to="/user-page">החשבון שלי</Link>
          <Link to="/welcome-back">חדשות</Link>
          <Link to={"#"} className="text-secondary" onClick={
            () => {toast.error("קישור לחוברות/מצגות לימוד , פתוח למשתמשי פרימיום מחוברים בלבד",{theme:"colored"})}
          } >חוברות און ליין</Link>
          <Link onClick={onExitClick} to="#">התנתק</Link>
          <Link to="/premium">מחירים/חבילות</Link>
        </React.Fragment>)
    }
  }

  const showHideNav = () => {
    if(!showNavMob){ document.querySelector(".my-nav").style.display = "block"; }
    else{ document.querySelector(".my-nav").style.display = "none"; }
    setShowNavMob(!showNavMob);
  }


  return (
    <header className='container-fluid shadow'>
      <div className="container">
        <div className="row align-items-center justify-content-between">
        {/* תפריט */}
          <nav onClick={showHideNav} className='col-xl-auto text-end my-nav d-xl-block'>
            {whatNavShow()}
          </nav>
          <div className="burger col-3 text-end pt-2">
          <i onClick={showHideNav} className="fa fa-bars h1" aria-hidden="true"></i>
          </div>
          <div className='col-9 col-lg-auto text-start'>
            <Link to="/">
              <img src="/images/logo_monkey.png" className='img-fluid logo' />
              {API_URL.includes("localhost") ? "lo" : ""}
            </Link>
          </div>
        </div>

      </div>
    </header>
  )
}

export default Header