import React, { useContext } from 'react';
import { Link, useNavigate,useParams } from 'react-router-dom';
import { toast } from "react-toastify"
import {KEY_VID} from "../../services/apiService"
import { AppContext } from '../context/context';

function VideoBtnItem(props) {
  const nav = useNavigate()
  const params = useParams()
  const {showAdsOn} = useContext(AppContext);
  // אם פרי שקר וגם פייד שקר יוצג באפור ולא יהיה ניתן ללחיצה
  // אם לוחצים יפתח פופ אפ שיציג הרשמה בתשלום

  const item = props.item;
  const changeVid = () => {
    // alert(item.url_video)
    console.log(item.url_video)
    if (item.video_company === "vimeo") {

      let code = item.url_video.substring(item.url_video.indexOf(".com/") + 5, 99);
      props.changeVideo(code, "vimeo", item._id, item)

      // NAV -> http://localhost:3000/player/880/hljr2r
      // course_id/short_id 
      // alert(st)
    }
    else if (item.video_company === "youtube") {
      let andIndex = (item.url_video.indexOf("&") > -1) ? item.url_video.indexOf("&") : 99;
      // console.log(andIndex)
      let vIndex = item.url_video.indexOf("watch?v=") + 8
      let code = item.url_video.substring(vIndex, andIndex);
      console.log(code)
      props.changeVideo(code, "youtube", item._id, item)
      // alert(st)
    }
    // save the videos in videosClicked_ar
    saveVideoClickedLocal()
    nav(`/player/${item.course_id}/${item.short_id}`);
  }

  const saveVideoClickedLocal = () => {
    console.log("local 22222222");
    let ar = []
    if(localStorage[KEY_VID]){
      ar = JSON.parse(localStorage[KEY_VID])
    }
    
    const indexItem = ar.findIndex(olditem => olditem._id == item._id);
    if(indexItem > -1){ ar.splice(indexItem,1)}
    console.log(indexItem);
    ar.unshift(item);
    ar.splice(6,Infinity);
    localStorage.setItem(KEY_VID,JSON.stringify(ar))
  }

  const whatIconShow = () => {
    // <i class="fa fa-check-square-o ms-2" aria-hidden="true"></i>
    // <i class="fa fa-square-o ms-2" aria-hidden="true"></i>

    if (item.play) {
      return (<i className="fa fa-caret-square-o-left ms-2" aria-hidden="true"></i>)
    }
    else if (item.watched) {
      return (<i className="fa fa-check-square-o ms-2" aria-hidden="true"></i>)
    }
    return (<i className="fa fa-square-o ms-2" aria-hidden="true"></i>)
  }

  const changeVidLink = () => {
    
    return "/player/"+params["courseId"]+"/"+item.short_id
  }

  // בודק אם המשתמש יכול לצפות בוידיאו או לא
  const videoFree = () => {
    if ((!item.free && item.paid) || item.free) {
      // rgb(243, 243, 243) -> צבע תכלת
      return (
        <Link style={{color:"black",textDecoration:"none"}} to={changeVidLink()}>
          <div onClick={() => { 
            saveVideoClickedLocal()
            // changeVid()
            // Todo: solve probelm of link to be in dark mode diffrent color
             }} style={{ background: item.play ? "#5000C8" : "",color:item.play ? "white" : "" }} className={`p-3 video_bar pe-4 ${item.play ? 'video_play' : ""}`} >
            {whatIconShow()}
            {item.counter + 1}. {item.title}
            {(item.video_company === "youtube" && localStorage["y"]) && <div className='badge bg-danger'>utube</div>}
          </div>
        </Link>
      )
    }
    else {
      return (<div onClick={showMessageBuySub} style={{ background: item.play ? "rgb(177, 213, 226)" : "", color: 'rgb(210,0,0)' }} className="p-3 video_bar pe-4" >
        <i className="fa fa-lock ms-2" aria-hidden="true"></i>
        <span style={{ color: "rgb(200,170,170)" }}>{item.counter + 1}. {item.title}</span>
        {(item.video_company === "youtube" && localStorage["y"]) && <div className='badge bg-danger'>utube</div>}
      </div>)
    }
  }

  const showMessageBuySub = () => {
    showAdsOn(true)
    // TODO2:show message window lightbox to buy
    toast.error(`סרטון זה פתוח לצפייה למנויי פרימיום בתשלום בלבד, לחץ כאן להמשך`, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      onClick: testAlert
    });
  }

  const testAlert = () => {
    nav("/premium")
    // alert("test")
  }
  // בודק םא נושא או סרטון ברשימה
  return (
    <React.Fragment  >
      {item.subject ?
        <div className="p-3 py-2 subject_bar ">
          <strong> פרק {item.counter + 1}: {item.title} </strong>
        </div> : videoFree()
      }
    </React.Fragment>
  )
}

export default VideoBtnItem