import React from 'react'
import "./loading.css"

export default function Loading() {
  return (
    <div className='text-center'>
      <img className='img_load' alt='loading' src="/images/load.png" width={130} />
      <p>טוען...</p>
    </div>
  )
}
