let adsInfo
const POP_KEY = "pop_up"

export const checkAds = (showAdsOn) => {
  if(!localStorage[POP_KEY]){
    localStorage.setItem(POP_KEY,JSON.stringify({visit:0,last:Date.now()}))
  }
  adsInfo = JSON.parse(localStorage.getItem(POP_KEY));
  adsInfo.visit ++;
  saveLocalAds()
  if(adsInfo.visit > 7){
    localStorage.setItem(POP_KEY,JSON.stringify({visit:0,last:Date.now()}))
    showAdsOn(true)
  }
  // FORTEST:DEL TO TURNOFF ADS
  // showAdsOn(true)
}

export const clearAds = () => {
  localStorage.setItem(POP_KEY,JSON.stringify({visit:0,last:Date.now()}))
}

const saveLocalAds = () => {
  localStorage.setItem(POP_KEY,JSON.stringify(adsInfo))
}
