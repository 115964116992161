import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function Carosela() {
  const h = 180;
  return (
  <Carousel  responsive={responsive} containerClass="align-items-center d-flex">
    <div className='me-2'><img src="https://monkeys.co.il/course/recommended/9_02_24B.jpg" className='img-fluid'   /></div>
    <div className='me-2'><img src="https://monkeys.co.il/course/recommended/9_02_24D.jpg" className='img-fluid'   /></div>
    <div className='me-2'><img src="https://monkeys.co.il/course/recommended/9_02_24H.jpg" className='img-fluid'   /></div>
    <div className='me-2'><img src="https://monkeys.co.il/course/recommended/9_02_24C.jpg" className='img-fluid'   /></div>
    <div className='me-2'><img src="https://monkeys.co.il/course/recommended/9_02_24G.jpg" className='img-fluid'   /></div>
    <div className='me-2'><img src="https://monkeys.co.il/course/recommended/9_02_24.jpg" className='img-fluid'  /></div>
    <div className='me-2'><img src="https://monkeys.co.il/course/recommended/9_02_24F.jpg" className='img-fluid'   /></div>
   
</Carousel>
  )
}
