import React from 'react'

export default function HomeFeatures() {
  return (
    <div className='container mb-5 features' style={{ maxWidth: "1100px", minHeight: "200px" }}>
      <div className='text-center mb-5'>
        <h2 className='h4' style={{ color: "#A76AFF" }}>יתרונות</h2>
        <h4 className='h2'>למה ללמוד קוד מאתר מאנקיס</h4>
      </div>
      <div className='row justify-content-between align-items-center my-4' >
        <section className='col-md-6'>
          <h2>שיטת לימוד ייחודית</h2>
          <p>
            נלמד אתכם את כל מה שצריך לדעת כדי לבנות אתרים ברמה גבוהה, תוך שימוש בשפות החשובות ביותר בתעשייה.
            <br />
            על ידי שיטת לימודית שפותחה על ידי צוות מומחים ותיקים,
            והיא הוכיחה את עצמה לאורך שנים בהכשרת מאות ואלפי סטודנטים.
            
          </p>
        </section>
        <div className='col-md-5'>
          <img src="https://images.pexels.com/photos/1779487/pexels-photo-1779487.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className='img-fluid' />
        </div>
      </div>
      <div className='row justify-content-between align-items-center' >
        <div className='col-md-5'>
          {/* <img src="https://images.pexels.com/photos/574069/pexels-photo-574069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className='img-fluid' /> */}
          <img src="https://images.pexels.com/photos/7245349/pexels-photo-7245349.jpeg?auto=compress&cs=tinysrgb&w=600" className='img-fluid' />
        </div>
        <section className='col-md-6'>
          <h2>דוגמאות ותרגולים מהתעשיה</h2>
          <p>
            אנו מאמינים בלמידה דרך עשייה, ולכן אנו משלבים
            לאורך כל הקורס תרגולים רבים שיאפשרו לכם ליישם את החומר הנלמד.
            <br />
            בסוף כל עונה ישנה עונת תרגולים, המשלבת בחלקה תרגולים מעשיים מהתעשיה
            וממרכזי לימוד שונים.
          </p>
        </section>

      </div>
      <div className='row justify-content-between align-items-center my-4' >
        <section className='col-md-6'>
          <h2>אפשרות קלה למעקב אחרי שיעורים</h2>
          <p>
            באתר מאנקיס כל מודול לימוד/נושא חדש מקטולג כעונה (בדומה לעונות מסדרות טליווזיה)
            ומספור העונות מייצג גם את רמת העונה ואת החומר שתצטרכו לפני
            (כאשר תמיד לפני כל עונה בסרטון הראשון שלה מוסבר איזה עונה/ות רצוי שתדעו לפני התחלתה )
            <br />
            בנוסף לכל סרטון שתתחילו לצפות, יישמר מקומית סימן שצפיתם בו
            כך שתוכלו לחזור לסרטון האחרון שצפיתם בקלות ובמהירות.
          </p>
        </section>
        <div className='col-md-5'>
          <img src="https://images.pexels.com/photos/8844564/pexels-photo-8844564.jpeg?auto=compress&cs=tinysrgb&w=600" className='img-fluid' />
        </div>
      </div>
      <div className='row justify-content-between align-items-center' >
        <div className='col-md-5'>
          <img src="https://images.pexels.com/photos/1309766/pexels-photo-1309766.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className='img-fluid' />
        </div>
        <section className='col-md-6'>
          <h2>קורסים לכל הרמות</h2>
          <p>
            בין אם אתם מתחילים טריים בעולם התכנות, או שועלי קרבות מנוסים המחפשים להרחיב את הידע שלהם, הקורסים שלנו מתאימים לכם!
            <br />
            שיטת הלימוד הייחודית שלנו מאפשרת לכם להתחיל בכל נושא ובכל רמה, וללמוד בקצב שלכם.
            <br />
            <br />
            קורסים מתאימים לכל מי שמעוניין ללמוד פיתוח Web, החל מגיל 14+.
            <br />
            נדרש ידע באנגלית ומתמטיקה ברמה סבירה (3 יחידות).
          </p>
        </section>

      </div>
    </div>
  )
}
