import React, { useEffect, useState } from 'react';
import { API_URL, doApiGet } from '../../services/apiService';
import ScrollTop from '../misc/scrollTop';
import CourseItem from './courseItem';
import Loading from '../misc/loading';

function AppCourses(props) {
  let [list_ar, setList] = useState([])

  useEffect(() => {
    document.title = "קורסי און ליין FULLSTACK פרימיום - מאנקיס , react, nodejs"
    doApi();
  }, []);

  const doApi = async () => {
    // TODO check local and if the time is not over 10 mins
    let data
    if (checkLocalTime("courses_vod2")) {
      console.log("local vod")
      data = JSON.parse(localStorage["courses_vod2"]).data
    }
    else {
      let url = API_URL + "/courses/?perPage=25";
      data = await doApiGet(url);
      localStorage.setItem("courses_vod2", JSON.stringify({ data, time: Date.now() }))

    }
    console.log(data);
    setList(data)
  }

  const checkLocalTime = (_key) => {
    if (localStorage[_key]) {
      let dataLocal = JSON.parse(localStorage[_key]);
      if (dataLocal.time + 60000 * 20 > Date.now()) {
        return true;
      }
      return false;
    }
    else {
      return false
    }
  }

  return (
    <div className='container-fluid courses'>
      <ScrollTop />
      <div className='container mt-4' style={{ minHeight: "80vh" }}>
      <h2 className='h3 text-center' style={{color:"#A76AFF"}} >
                  הקורסים שלנו, לחץ על קורס והתחל לצפות

        </h2>
        <h4 className='text-center h6'>
         לנוחיותיכם - תוכלו ללמוד בקצב המומלץ (לפי מספר השבוע הרשום)
        </h4>
        <h4 className='text-center h6'>
          🎁 - אייקון המציג עונה חופשית לכל המשתמשים
        </h4>
        <div className="row justify-content-center">
          
          {list_ar.length == 0 ? <Loading /> : ""}
          {list_ar.map(item => {
            if (item.position !== 1000) {
              return (
                <CourseItem key={item._id} item={item} />
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default AppCourses