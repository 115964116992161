// בדיקה טסט ללוגן אין החדש של גוגל
import React, { useContext, useEffect } from 'react';
// import GoogleLogin from 'react-google-login';
// google 2023 https://www.npmjs.com/package/@react-oauth/google
import { GoogleOAuthProvider, GoogleLogin as GoogLogin } from '@react-oauth/google';

// https://console.cloud.google.com/ בשביל לערוך האפליקציה נקראת VOD monkeys
import { Link, useNavigate,useSearchParams } from 'react-router-dom';
// for login info of google 2023
import jwt from "jwt-decode"
import { API_URL, doApiMethod } from '../../services/apiService';
import { AppContext } from "../context/context"

function Login2(props) {

  const { updateInfo, info } = useContext(AppContext);
  const [query] = useSearchParams();
  const backTo = query.get("backto");
  // console.log(info)
  const nav = useNavigate()

  const handelGoogle2023 = (resp) => {
    // console.log("2023",resp); 
    let decode = jwt(resp.credential);
    // console.log(decode);
    doGoogleApi(decode);
  }

  useEffect(() => {
    //?backto = לאן להחזיר את המשתמש אחרי ההתחברות
    console.log(query.get("backto"));

  }, [])

  const handleLogin = (googleData) => {
    console.log(googleData)
    // console.log(googleData.su.FX, googleData.su.UV,googleData.su.ev,googleData.su.pW)
    // doGoogleApi(googleData);
  }

  const doGoogleApi = (googleData) => {
    // console.log(googleData);
    let username = googleData.given_name + " " + googleData.family_name;
    username = username.length >= 2 ? username : "Guest"
    let body = {
      name: username,
      email: googleData.email,
      password: googleData.email + "3333",
      token: googleData.sub
    }

    let url = API_URL + "/users/google_check"
    // console.log(body)
    doApiMethod(url, "POST", body)
      .then(data => {
        console.log(data);
        if (data._id) {
          doGoogleApi(googleData)
        }
        else if (data.token) {
          // alert("All good") ;
          body.password = "******"
          body.token = "*********"
          body.email = "*********"
          body.login = true
          let rnd = getRandomId(1000,9999)
          body.paypalId = rnd
          // קורסים ששולם עליהם
          body.forfor = data.courses_paid_for.map(val => {
            return val * rnd + 20;
          })
          localStorage.setItem("user_monkeys", body.name)
          localStorage.setItem("tok_monkeys", data.token)
          // מידע שישמר בלוקאל ויהיה בקונטקסט
          localStorage.setItem("body_monkeys", JSON.stringify(body));
          updateInfo(body);
          (backTo) ? nav(backTo) : nav("/welcome-back");
          
          // checkIfPaid()
          setTimeout(() => {
            window.location.reload();
          }, 600);
        }
      })
      .catch(err => {
        console.log(err)
        alert("there problem")
      })
  }

  const handleError = (err) => {
    alert(err);
    alert("ישנה תקלה , נסה מאוחר יותר שוב")
  }

  let googleClient2023 = "234085240640-93kr3fvula8qkfpc0hp3fdsatcm1jkbl.apps.googleusercontent.com"

  return (
    <div className='container login text-center mt-3 d-flex align-items-center' style={{ minHeight: "82vh " }}>
      <article className='col-md-8 mx-auto shadow p-4 rounded'>
        <h1 className='' style={{color:"gold", textShadow:"4px 3px 0 #111"}}>התחברות למערכת MONKEYS PASS </h1>
        <h3 className='mt-4 h5'>נכון לרגע זה ניתן להתחבר רק עם חשבון גוגל מאומת למערכת</h3>
        {/* <h4 className='mt-4 h5'>למשתמשים ותיקים: דרך חדשה להתחברות - יש ללחוץ SIGN IN</h4> */}
        <div className='my-4 d-flex justify-content-center'>
          <GoogleOAuthProvider clientId={googleClient2023}>
            <GoogLogin
              onSuccess={handelGoogle2023}
              onError={(err) => {
                console.log('Login Failed',err);
              }}
              
            />
          </GoogleOAuthProvider>
          <div className='bg-dark border p-1 rounded '>
            {/* <GoogleLogin
              clientId='322697445966-ra6eqm6pvfk51vt7gj372jo0r9lvlk4e.apps.googleusercontent.com'
              buttonText='- התחבר/הרשם עם חשבון גוגל -'
              onSuccess={handleLogin}
              onFailure={handleError}
              cookiePolicy={'single_host_origin'}
            ></GoogleLogin> */}
          </div>
        </div>
        <h4 className='h6 col-md-8 mx-auto'>* לאחר לחיצה על הכפתור התחבר עם חשבון גוגל תופנה לחלון בשרת
          גוגל שיאשר למאנקיס שהינך משתמש אמיתי
          לאחר לחיצה על הכפתור (ובחירת המייל יש להמתין כ5 שניות עד הכניסה)
        </h4>
        <Link className='h6  mt-4' to="/login2">במידה ואינך מצליח להתחבר לחץ כאן</Link>
      </article>
    </div>
  )
}

export default Login2

const getRandomId = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
}