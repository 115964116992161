import React, { useEffect } from 'react';

function ScrollTop(props){

  useEffect(() => {
    window.scrollTo(0, 0)
  },[])

  return(
    <React.Fragment></React.Fragment> 
  )
}

export default ScrollTop