


// export const API_URL = window.location.hostname == "localhost" ? 
// "http://localhost:3001" : "https://monkeys-vod.herokuapp.com/"
// API REAL REACT
// export const API_URL =  "http://localhost:3001";
export const API_URL =  "https://monkeys-vod.herokuapp.com";
// localstorage key for movies been clicked
export const KEY_VID = "videosClicked_ar"

export const doApiGet = async(_url) => {
  // console.log(window.location.hostname)
  let resp = await fetch(_url,{
    method:"GET",
    headers: { 
      "x-api-key":localStorage["tok_monkeys"],
      'content-type': "application/json"
   } })
  let data = await resp.json();
  // פונקציה שהיא אסינק אוטומטית מחזירה את המידע כפרומיס
  return data;
}


export const doApiMethod = async(_url, _method, _body) => {
  try{
  let resp = await fetch(_url,{
    method:_method,
    body:JSON.stringify(_body),
    headers: { 
      "x-api-key":localStorage["tok_monkeys"],
      'content-type': "application/json"
   } })
  let data = await resp.json();
  // פונקציה שהיא אסינק אוטומטית מחזירה את המידע כפרומיס
  return data;}
  catch(err){
    return err;
  }
}

// פונקציה שיודעת לבצע מיון לפי מערך איי די 
export const sortById = (sort_ar, _videos_ar) => {
  let newSort_ar = []
  _videos_ar.forEach((item, i) => {
    // console.log(item,sortid_ar[i])
    newSort_ar[sort_ar.indexOf(item.short_id)] = item;
  })
  return newSort_ar;
}