import React, { useEffect } from 'react';



function InfoPlayer(props) {
  let dataCourse = props.course;
  let video = props.video;
  console.log(video)
  

  useEffect(() => {
    if(props.video.title){
      document.title = props.video.title + " | מאנקיס פרימיום"

    }
    else if (props.course.title) {
      document.title = props.course.title + " | מאנקיס פרימיום"
    }
  }, [props.course,props.video])

  return (
    <div className="info_movie p-3">
      {video?.title ?
        <h1 className=''>{video.title}</h1> : ""
      }
      {(localStorage["y"]) ? <h5>{video.url_video}</h5> : ""}
      <article className='d-none d-md-block'>
        {video?.info ?
          <p dangerouslySetInnerHTML={{
            __html: video.info
          }}></p> : ""
        }
        <hr />
        <h3>{dataCourse.title}</h3>
        <p>{dataCourse.info}</p>
        <p><strong>מדריך הקורס:</strong> {dataCourse.author}</p>
      </article>
    </div>
  )
}

export default InfoPlayer





// author: "עופר שלי - מרצה מנוסה עם מעל 15 שנות וותק בפיתוח וניהול פרוייקטים ומעל 10 שנים בתחום ההדרכה"
// category: "general"
// date_created: "2021-12-13T19:57:40.182Z"
// img_url: "https://monkeys.co.il/course/course2_bs.jpg"
// info: "קורס בסיס של BS 5 עם תרגול של יציתרת דף נחיתה שלם"
// position: 0
// price: 250
// short_id: "992"
// skill_needed: "HTML + CSS , כל עונה 1 במאנקיס"
// title: "קורס BOOTSTRAP 5 - מלא 12"
// user_id: "61b631c2b4f2f6ae4116feb8"