// 🎲🎲🎲🎲 לא בשימוש

import React, { useContext } from 'react';
import GoogleLogin from 'react-google-login';
// https://console.cloud.google.com/ בשביל לערוך האפליקציה נקראת VOD monkeys
import { Link, useNavigate } from 'react-router-dom';
import { API_URL, doApiMethod } from '../../services/apiService';
import {AppContext} from "../context/context"

function Login(props) {

  let {updateInfo,info} = useContext(AppContext);
  // console.log(info)
  let nav = useNavigate()

  const handleLogin = (googleData) => {
    // console.log(googleData)
    // console.log(googleData.su.FX, googleData.su.UV,googleData.su.ev,googleData.su.pW)
    doGoogleApi(googleData);
  }

  const doGoogleApi = (googleData) => {
    // console.log(googleData);
    let username = googleData.profileObj.givenName + " " + googleData.profileObj.familyName;
    username = username.length >= 2 ? username : "Guest"
    let body = {
      name: username,
      email: googleData.profileObj.email,
      password: googleData.profileObj.email + "3333",
      token: googleData.profileObj.googleId
    }

    let url = API_URL + "/users/google_check"
    // console.log(body)
    doApiMethod(url, "POST", body)
      .then(data => {
        // console.log("data goog",data);
        // localStorage.setItem("test_ofer",JSON.stringify(data))
        if (data._id) {
          doGoogleApi(googleData)
        }
        else if (data.token) {
          // alert("All good") ;
          body.password = "******"
          body.token = "*********"
          body.email = "*********"
          body.login = true
          localStorage.setItem("user_monkeys", body.name)
          localStorage.setItem("tok_monkeys", data.token)
          // מידע שישמר בלוקאל ויהיה בקונטקסט
          localStorage.setItem("body_monkeys", JSON.stringify(body));
          updateInfo(body);
          nav("/welcome-back")
          // checkIfPaid()
          setTimeout(() => {
            // window.location.reload();  
          },600);
        }
      })
      .catch(err => {
        console.log(err)
        alert("there problem")
      })
  }

  const handleError = (err) => {
    alert(err);
    alert("ישנה תקלה , נסה מאוחר יותר שוב")
  }

  return (
    <div className='container login text-center mt-3 d-flex align-items-center' style={{ minHeight: "82vh " }}>
      <article className='col-md-8 mx-auto shadow p-4 rounded'>
        <h1>התחברות למערכת MONKEYS PASS</h1>
        <h3 className='mt-4'>נכון לרגע זה ניתן להתחבר רק עם חשבון גוגל מאומת למערכת</h3>
        <div className='my-4 d-flex justify-content-center'>
          <div className='bg-dark border p-1 rounded '>
          <GoogleLogin
            clientId='322697445966-ra6eqm6pvfk51vt7gj372jo0r9lvlk4e.apps.googleusercontent.com'
            buttonText='- התחבר/הרשם עם חשבון גוגל -'
            onSuccess={handleLogin}
            onFailure={handleError}
            cookiePolicy={'single_host_origin'}
          ></GoogleLogin>
          </div>
        </div>
        <h4 className='h5 col-md-6 mx-auto'>* לאחר לחיצה על הכפתור התחבר עם חשבון גוגל תופנה לחלון בשרת
          גוגל שיאשר למאנקיס שהינך משתמש אמיתי
        </h4>
       
      </article>
    </div>
  )
}

export default Login