import React, { useContext } from 'react'
import { AppContext } from '../context/context'
import "./adWindow.css"
import { useNavigate } from 'react-router-dom';

export default function AdWindow() {
  const { ads, showAdsOn } = useContext(AppContext);
  const nav = useNavigate();
  return (
    <React.Fragment>
      {ads && <div className='persom'>
        <div className='box_persom shadow'>
          <article className='d-flex'>

            <div className='bg col-1 col-sm-2 col-md-4 shadow-sm' style={{
              backgroundSize:"cover",backgroundPosition:"center",
              backgroundImage:"url(/images/persom.jpg)"
            }}>
              
            </div>
            <div>
              <h2 className='text-light me-2 h3 mt-2 mt-sm-5'>
                עשו היום מנוי למאנקיס וקבלו
              </h2>
              <ul className='me-2 rounded-3 shadow w-100 h6'>
                <li>מעל 500 סרטונים במנוי</li>
                <li>כל הסרטונים שבאתר פתוחים לצפייה</li>
                <li>יותר מ80 שעות של סרטונים בעברית</li>
                <li>גישה לקבצים מסרטונים נתמכים</li>
                <li>עונות מיוחדות הכוללות תרגולים</li>
                <li>גישה למנוי/אתר מכל מכשיר</li>
                {/* <li>עדכונים על סרטוני פרימיום חדשים</li> */}
              </ul>
              <p className='me-3'>* במידה והנך מנוי פרימיום לחץ התחברות</p>
            </div>
          </article>
          <div className='btns-persom'>

            <button onClick={() => {
              nav("/premium")
              showAdsOn(false)
            }} className='btn btn-success ms-2'>רכוש מנוי</button>
            <button onClick={() => {
              nav("/login")
              showAdsOn(false)
            }} className='btn btn-info ms-2'>התחברות </button>
            <button onClick={() => {
              showAdsOn(false)
            }} className='btn btn-danger'>סגור </button>
          </div>
        </div>
      </div>}
    </React.Fragment>
  )
}
