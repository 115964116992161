import React, { useLayoutEffect, useState } from 'react'

let dt = new Date();
export default function AlertBottom() {

  const [showMessage, setShowMessage] = useState(true);

  useLayoutEffect(() => {
 
    
    if(localStorage.getItem("message11") == dt.getDate()){
      setShowMessage(false)
    }
  },[])

  const hideMessage = () => {
    setShowMessage(false)
    
   
    localStorage.setItem("message11",dt.getDate());
  }

  return (
    <React.Fragment>
      {showMessage && 
      <div style={{
        background: "red",
        color: "white",
        fontSize: "1.5em",
        padding: "8px"
      }}>
        <button onClick={hideMessage} className='bg-dark text-white float-start btn '>סגור ואשר</button>
        הודעה חשובה: החל מהשבוע מאנקיס פרימיום עובר שדרוג וייתכנו שיבושים באתר למשך יממה, עמכם הסליחה
     
      </div> }
    </React.Fragment>
  )
}
