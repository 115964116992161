import React, { useEffect, useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import { API_URL, doApiGet } from '../../services/apiService';
import AuthComp from '../authComp';
import ScrollTop from '../misc/scrollTop';
import {AppContext} from "../context/context"

function UserPage(props) {
  let [userInfo, setUserInfo] = useState({});
  const {darkMode, changeDarkMode} = useContext(AppContext)
  useEffect(() => {
    doApi();
  }, [darkMode])

  const doApi = async () => {
    let url = API_URL + "/users/myInfo";
    let data = await doApiGet(url);
    console.log(data);
    // data.date_created = data.date_created.substring(0,data.date_created.indexOf("T") )
    setUserInfo(data);
  }

  const dateIsrael = (_date) => {
    if (_date) {
      let dt = _date.substring(0, _date.indexOf("T"));
      let dtar = dt.split("-");
      dtar.reverse();
      dt = dtar.join("/")
      return dt;
    }
  }

  return (
    <div className='container-fluid welcome_out user-page'>
      <AuthComp />
      <ScrollTop />
      <div style={{ minHeight: "85vh" }} className='d-flex align-items-center container welcome py-4 info' >
        <article className='w-100'>
          <h1 className='my-4 text-center'>פרטי/הגדרות המשתמש שלך</h1>
          <div className="row">
            <div className="col-md-6 p-3">
              <div className="p-3 border bg-light shadow rounded">
                <h5>שם: {userInfo.name}</h5>
                <h5>אימייל רשום: {userInfo.email}</h5>
                <h5>סוג חשבון: {userInfo.role == "user" ?
                  "חינמי"
                  : "משלם"}</h5>
                <h5>תאריך הרשמה: {dateIsrael(userInfo.date_created)}</h5>
                <div className='d-flex'>
                  <input defaultChecked={darkMode == "light" ? true : false} checked={darkMode == "light" ? true : false} type="checkbox"  onClick={(e) => {
                    changeDarkMode();
                  }} />
                  <h5 className='me-2'>מצב תצוגה בהיר (רקע לבן)</h5>
                </div>

              </div>
            </div>
            {userInfo.role != "user" ?
              <article className="col-md-6 p-3">
                <div className="p-3 border bg-light shadow rounded">
                  <h5 className='text-primary'>נתוני מנוי פרימיום</h5>
                  <h5>תאריך תשלום למנוי פרימיום: {dateIsrael(userInfo.date_paid)}</h5>
                  <h5>תאריך סיום מנוי פרימיום: <span className='text-danger'> {dateIsrael(userInfo.date_paid_up_to)}</span></h5>
                  <h5>לחידוש מנוי: <Link to="/premium">לחץ כאן</Link></h5>
                </div>
              </article> : ""}
          </div>
        </article>
      </div>
    </div>
  )
}

export default UserPage