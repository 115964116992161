export const codeVideo = (_url,_company) => {
  if (_company === "vimeo") {

    let code = _url.substring(_url.indexOf(".com/") + 5, 99);
    return code;

  }
  else if (_company === "youtube") {
    let andIndex = (_url.indexOf("&") > -1) ? _url.indexOf("&") : 99;
    let vIndex = _url.indexOf("watch?v=") + 8
    let code = _url.substring(vIndex, andIndex);
    return code;

  }
}