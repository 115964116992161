import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { API_URL, KEY_VID, doApiGet } from '../../../services/apiService';
import CourseItem from '../../courses/courseItem';
import Loading from '../../misc/loading';

export default function NewVideoslist(props) {
  const [cats, setCats] = useState({})
  const [new_ar, setNewAr] = useState([])
  const [loading, setLoading] = useState(true);
  const [last_ar, setLastAr] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    doApi();
    getLastVideosClicked();
  }, []);

  const getLastVideosClicked = () => {
    if (localStorage[KEY_VID]) {
      setLastAr(JSON.parse(localStorage[KEY_VID]));
    }
  }

  const doApi = async () => {
    setLoading(true);
    let url = API_URL + "/courses?perPage=99";
    let data = await doApiGet(url);
    console.log(data);
    let obj = {}
    data.map(item => {
      obj[item.short_id] = { img_url: item.img_url, name: item.title }
    })
    setCats(obj)
    url = API_URL + "/videos/newVideos"
    let data2 = await doApiGet(url);
    console.log(data2);
    setNewAr(data2);
    setLoading(false);

  }

  return (
    <div className='container courses' style={{ minHeight: "200px" }}>
        {last_ar.length > 0 ?
      <div>
        <h4 className='text-center'>סרטונים אחרונים שצפית בהם:</h4>
        <div className="row text-center">
          {loading && <Loading />}
          {last_ar.map((item, i) => {
            if (i < 4) {
              return (
                <div className='col-md-12 border border-dark my-1 shadow-sm p-1 bg-light new_course_item'>
                  <h6 className='m-0'>{cats[item.course_id]?.name}</h6>
                  <div style={{ cursor: "pointer" }} className='h5 p-0 m-0' onClick={() => {
                    nav(`/player/${item.course_id}/${item.short_id}`)
                  }}>
                    {item.title}
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
      : ""}
      <h4 className='text-center'>
        סרטונים אחרונים שהועלו במאנקיס פרימיום:
      </h4>
      <div className="row text-center">
        {loading && ""}
        {new_ar.map((item, i) => {
          if (i < 6) {
            return (
              <div className='col-md-12 border border-dark my-1 shadow-sm p-1 bg-light new_course_item'>
                <h6 className='m-0'>{cats[item.course_id]?.name}</h6>
                <div style={{ cursor: "pointer" }} className='h5 p-0 m-0' onClick={() => {
                  nav(`/player/${item.course_id}/${item.short_id}`)
                }}>
                  {item.title}
                </div>
              </div>
            )
          }
        })}
        {/* TODO: להוסיף סרטונים אחרוננים שהמשתמש צפה בהם */}
        {/* /videos/group */}
        {/* {new_ar.map(item => {
          return (
            <div key={item._id} className={`${props.cssWidth} p-2`}>
              <div className="box_course text-white h-100" style={{ background: "black", position: "relative" }}>
                <div className="box_pic" style={{ minHeight: "100px", backgroundImage: `url(${cats[item.course_id]?.img_url})` }}>
                  <img src={cats[item.course_id]?.img_url} alt="סרטון חדש" className="w-100 d-block d-md-none" />
                </div>
                <h4 className='p-2 h5 mb-4'>{item.title}</h4>
                <div className='w-100' style={{ position: "absolute", bottom: "18px" }}>
                  <Link to={`/player/${item.course_id}/${item.short_id}`}  >קישור לסרטון</Link>
                </div>
              </div>
            </div>
          )
        })} */}
      </div>
      {/* TODO CREATE to LIST give image , name and link to */}
    </div>
  )
}
