import React from 'react'
import { Link } from 'react-router-dom'

export default function ShowMe() {
  return (
    <div className='container mb-5  row mx-auto align-items-center my-5' style={{ minHeight: "40px", maxWidth: "1160px" }}>
      <div className='col-md-6'>
        <h2 className='h4' style={{ color: "#A76AFF" }}>שלום,</h2>
        <h3>וברוכים הבאים</h3>
        <p>
          מתקשים ללמוד פולסטאק WEB או שרק התחלתם בלימודים?
          <br />
          מרגישים שהחומר מורכב מדי או שהמרצה לא מסביר בצורה ברורה?
          <br />
          <br />
          אל דאגה! יש פתרון!
          <br />
          השיטה הייחודית שפותחה על ידי ומרצים נוספים במהלך 10 השנים האחרונות תעזור לכם ללמוד בצורה שונה לחלוטין
          במהירות וביעילות:
          <br />
          <strong>החל מ </strong>
          HTML 5, CSS 3, JS Vanila
          <br />
          <strong>ועד +</strong>
          React, Node.JS/express, Next.JS,MongoDb, Sql, ECMA 6
        </p>
      </div>
      <div className='col-md-6'>

        <iframe width="100%" height="400" src="https://www.youtube.com/embed/MCG9e4xGt3E?si=ayudKKgBYj4dEKog" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className='box_benefit'></iframe>

      </div>
      <div>
        <div className='text-center my-5'>
          <Link to="/courses_vod/#" className='display-6 text-center text-white btn btn-info' style={{ fontSize: "2em", backgroundColor: "#5000C8", border: "1px solid black" }}>לרשימת הקורסים המלאה</Link>
        </div>
      </div>
    </div>
  )
}
