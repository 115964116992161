import React from 'react';
import { Link } from 'react-router-dom';
import CourseHomeItem from './home_items/courseHomeItem';

function CoursesList(props) {
  return (
    <div className='container-fluid courses'>
      <div className='container'>
        <h2 className='text-center h4' style={{color:"#A76AFF"}}>
          קורסים פופלרים שנמצאים בחבילת המנויים של MONKEYS PASS
        </h2>
        <h2 className='h2 text-center mb-4'>מעל 20 קורסים ויותר מ500 סרטונים בעברית במנוי אחד !</h2>
        <div className="row justify-content-center">
          
          <CourseHomeItem bg="/images/course4_react.jpg" title="React js Hooks" 
          txt="קורס מעודכן לתעשיה הכולל בתוכו את רידקס וספריות צד לקוח פופלריות נוספות" link="/player/880" />
          {/* end box */}
        
          <CourseHomeItem bg="/images/course3_node.jpg" title="NODEJS + EXPRESS" 
          txt="קורס מקיף על צד שרת שכל מפתח FULLSTACK חייב להכיר בשוק העבודה" link="/player/900" />
          {/* end box */}
          <CourseHomeItem bg="/images/course1.jpg" title="JS - מתרגלים ביחד" txt="עשרות תרגילים שבחלקם הגדול נפתור
          יחד, בכל שבוע יתווספו תרגולים נוספים" link="/player/991" />
          <CourseHomeItem bg="https://monkeys.co.il/course/next_sm.jpg" title="קורס NEXT.JS" txt="הספרייה הבאה של חברת מטא המשלבת ריאקט בצד השרת ומבוקשת כרגע בתעשיה" link="/player/134" />

          <CourseHomeItem bg="https://monkeys.co.il/course/css3_basic.jpg" title="קורס HTML 5/CSS 3 מתקדם" 
          txt="קורס בסיס לכניסה לתחום הפולסטאק , לימוד HTML/CSS עם הבנה מלאה של כל מושגי הבסיס
           " 
          link="/player/188" />
          {/* end box */}
        </div>
        
        {/* <h2 className='display-6 text-center my-5'>
          הרשמו עוד היום והכינו את עצמכם לשוק העבודה/פרילנס

        </h2>
        <div className='text-center'>
          <Link to="/premium#" className='display-6 text-center btn btn-info px-5' style={{ fontSize: "2em" }}>להרשמה לחצו כאן</Link>
        </div> */}
      </div>
    </div>
  )
}

export default CoursesList