import React from 'react';
import { Link } from 'react-router-dom';

function CourseHomeItem(props) {
  return (
    <div className="col-md-4 p-2 mb-4">
      <div className="box_course text-white" style={{ background: "black" }}>
        <div className="box_pic" style={{ backgroundImage: `url(${props.bg})` }}>
          <img src={props.bg} alt="קורס פופלרי" className="w-100 d-block d-md-none" />
        </div>
        <h3>{props.title}</h3>
        <p className='p-2'>{props.txt}</p>
        <Link to={props.link}>סרטוני הקורס</Link>
      </div>
    </div>
  )
}

export default CourseHomeItem